$dark: #0C0C0E;
$white: #ffffff;
$red: #FF0099;
$green: #129310;
$danger: #f70000;
$gray: #969696;
$color-33: #333333;
$color-D2: #D2D2D2;
$color-F6: #F69FFF;
$color-36: #36D1DC;
$color-5B: #5B86E5;
$color-66: #666666;
$color-B2: #B2B2B2;
$color-BB: #BBBBBB;
$color-AA: #666666;

// nft
$red: #ff5757;
$dark: #0C0C0E;
$white: #ffffff;
$blue: #0066ff;
$green: #129310;
$yellow: #ff9e12;
$color-6e: #6e6e6e;
$color-e6: #e6e6e6;
$color-ed: #ededed;
$color-c5: #c5c5c5;
$color-cc: #cccccc;