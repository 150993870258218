.header{
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    padding-top: 20px;
    position: absolute;
    padding-bottom: 20px;

    @include maxDevice(1279px){
        padding-top: 12px;
        
        .btn{
            font-size: 13px;
            padding: 10px 12px;
        }
    }
    
    @include maxDevice(1199px){
        padding: 15px 0;

        &:after{
            bottom: 0;
            left: 32px;
            z-index: 1;
            right: 32px;
            content: "";
            height: 1px;
            position: absolute;
            border-bottom: 1px dashed $color-66;
        }

        .navbar{
            &-collapse{
                background: rgb(13,11,18);
                background: -moz-linear-gradient(0deg, rgba(13,11,18,1) 0%, rgba(12,12,14,1) 100%);
                background: -webkit-linear-gradient(0deg, rgba(13,11,18,1) 0%, rgba(12,12,14,1) 100%);
                background: linear-gradient(0deg, rgba(13,11,18,1) 0%, rgba(12,12,14,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0d0b12",endColorstr="#0c0c0e",GradientType=1);
                @include transition(all .35s ease);
                min-height: 100% !important;
                height: 100% !important;
                position: fixed;
                display: block;
                overflow: auto;
                padding: 25px;
                z-index: 999;
                width: 100%;
                top: -100%;
                left: 0;

                &.show{
                    top: 0;
                }
            }

            &-controls{
                margin-bottom: 13px;
                justify-content: center;
            }

            &-nav{
                font-size: 22px;
                @include flexbox();

                @include maxDevice(1199px){
                    font-size: 15px;
                }
                
                a{
                    margin-bottom: 5px;

                    @include maxDevice(1199px){
                        margin-bottom: 5px;
                        @include flexbox();
                        @include align-items(center);

                        svg{
                            width: 25px;
                            height: 25px;
                            color: $dark;
                            padding: 4px;
                            margin-right: 4px;
                            border-radius: 4px;
                            background-color: $white;
                        }
                    }
                }
            }
        }
    }

    @include maxDevice(767px){
        padding: 15px 0;
    }
    
}

.navbar{
    &-control{
        font-weight: 700;
        margin-left: auto;  
    }
    &-brand{
        color: $white !important;
        font-weight: 700;

        img{
            @include maxDevice(1279px){
                max-width: 200px;
            }
        }
    }

    &-toggler{
        padding: 0;
        border: none;
    }

    &-nav{
        .dropdown{
            .btn{
                color: $white;

                &:after{
                    margin-left: 2px;
                    margin-right: 5px;
                }
            }
        }
        a{
            position: relative;
            color: $color-D2 !important;
            padding: 5px 15px !important;

            &:after{
                bottom: 0;
                left: 15px;
                opacity: 0;
                right: 15px;
                height: 1px;
                content: "";
                position: absolute;
                background-color: $white;

                @include maxDevice(1199px){
                    display: none;
                }
            }

            @include maxDevice(1439px){
                padding: 5px 10px !important;
            }

            @include maxDevice(1199px){
                padding: 2px 10px !important;
            }

            &:hover, &.active{
                color: $white !important;
                font-weight: 700;

                &:after{
                    opacity: 1;
                }
            }

            .badge{
                background-color: transparent !important;
                position: absolute;
                font-weight: 700;
                font-size: 8px;
                color: $red;
                padding: 0;
                right: 0;
                top: 1px;
            }
        }

        &-inner{
            a{
                padding: 5px 8px !important;

                &:after{
                    left: 8px !important;
                    right: 8px !important;
                }
            }
        }
    }
}

.dropdown{
    &-toggle{
        .badge{
            top: -2px !important;
        }
    }
}